import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import './button-text-promo.css';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ButtonTextPromoProps {
    colorClass: string,
    className?: string,
    btnSize: any,
    text: string,
    inverse?: boolean,
    href?: string,
    onClick?: (e: any) => void,
    subtext?: string,
    disabled?: boolean,
    hasSpinner?: boolean
    type?: any
}

export const ButtonTextPromo = forwardRef((props: ButtonTextPromoProps, ref: any) => {
    const navigate = useNavigate();

    const handleClick = (e: any) => {
        if (props.href) {
            e.preventDefault();
            navigate(props.href);
        }
        if (props.onClick) {
            props.onClick(e);
        }
    };

    return (
        <Button
            ref={ref}
            className={`${props.className ? props.className : ''} ${props.inverse ? 'inverse' : ''}`}
            onClick={handleClick}
            variant="text"
            type={props.type}
            size={props.btnSize}
            disabled={props.disabled}
            href={props.href}
        >
            <span className={`bg-btn ${props.colorClass}`} />
            <span className={
                // eslint-disable-next-line no-nested-ternary
                `${props.btnSize === 'sm' ? 'p-2 fs-6' : (props.btnSize === 'lg' ? 'p-3 fs-4' : 'p-2 fs-5')} ${
                    (props.colorClass === 'bg-secondary'
                        || props.colorClass === 'bg-white'
                        || props.colorClass === 'bg-light') ? 'text-dark' : ''}`
            }
            >
        {props.hasSpinner && <Spinner animation="border" size="sm" />}
                {' '}
                {props.text}
      </span>
            {
                props.subtext
                    ? <span className="bg-danger count-block"><strong>{props.subtext}</strong></span>
                    : ''
            }
        </Button>
    );
});
