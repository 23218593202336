import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { ButtonTextPromo } from '../button-text-promo';
import { appInsights } from "../../utils/appInsights";

interface FormState {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    jobTitle: string;
    phone: string;
    message: string;
    concent: boolean;
}

type DemoRequestFormProps = {
    onBadRequest: (state: boolean) => void,
    onTooManyRequests: (state: boolean) => void,
    onSuccess: (name: string, state: boolean) => void,
    onError: (state: boolean) => void,
};

export default function DemoRequestForm({ onBadRequest, onTooManyRequests, onSuccess, onError }: DemoRequestFormProps) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState<FormState>({
        email: '',
        firstName: '',
        lastName: '',
        company: '',
        jobTitle: '',
        phone: '',
        message: '',
        concent: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, type } = event.target;
        const value = type === 'checkbox' ? (event.target as HTMLInputElement).checked : event.target.value;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
      };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Process the form data here
        // For example, sending data to a server or logging it to the console
        // console.log(formData);

        if(!formData.concent) {
            console.error('Consent not given');
            onBadRequest(true);
            return;
        }

        // check fields are not empty, trim spaces
        if (formData.email.trim() === '' || formData.firstName.trim() === '' || formData.lastName.trim() === '' || formData.company.trim() === '' || formData.jobTitle.trim() === '' || formData.phone.trim() === '' || formData.message.trim() === '') {
            console.error('Form fields are empty');
            onBadRequest(true);
            return;
        }

        setIsSubmitting(true);
        const apiEndpoint = "api/queueEmail";
        if (!apiEndpoint) throw new Error('API endpoint not set');
        // make post request to apiEndpoint
        fetch(apiEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        }).then(response => {
            setIsSubmitting(false);
            if (response.status === 429) {
                console.error('Too many requests');
                appInsights.trackEvent({ name: 'TooManyRequests' });
                onTooManyRequests(true);
                // Handle 429 status here
                // For example, you might want to show a specific error message or retry the request after a delay
            } else if (response.status === 400) {
                console.error('Bad Request');
                appInsights.trackEvent({ name: 'BadRequest', properties: { formData: JSON.stringify(formData) } });
                onBadRequest(true);
            }
            else if (response.status >= 300) {
                console.error('Error:', response);
                appInsights.trackEvent({ name: 'ServerError', properties: { formData: JSON.stringify(formData) } });
                onError(true);
            }
            else {
                return response.json();
            }
        }).then(data => {
            if (data) {
                // console.log('Success:', data);
                onSuccess(`${formData.firstName} ${formData.lastName}`, true);
                appInsights.trackEvent({ name: 'Demo Request', properties: { formData: JSON.stringify(formData) } });
            }
        }).catch((error) => {
            appInsights.trackEvent({ name: 'ServerError', properties: { formData: JSON.stringify(formData) } });
            console.error('Error:', error);
            onError(true);
            setIsSubmitting(false);
        });

    };

    return (
        <Form onSubmit={handleSubmit} className="vstack">
            <Form.Group controlId="formEmail">
                <Form.Label className="ego-field-label-white mt-3">* E-Mail-Adresse:</Form.Label>
                <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formFirstName">
                <Form.Label className="ego-field-label-white mt-3">* Vorname:</Form.Label>
                <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formLastName">
                <Form.Label className="ego-field-label-white mt-3">* Nachname:</Form.Label>
                <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formCompany">
                <Form.Label className="ego-field-label-white mt-3">* Unternehmen:</Form.Label>
                <Form.Control type="text" name="company" value={formData.company} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formJobTitle">
                <Form.Label className="ego-field-label-white mt-3">* Berufsbezeichnung:</Form.Label>
                <Form.Control type="text" name="jobTitle" value={formData.jobTitle} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formPhone">
                <Form.Label className="ego-field-label-white mt-3">* Telefonnummer:</Form.Label>
                <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formMessage">
                <Form.Label className="ego-field-label-white mt-3">* Was möchten Sie besprechen:</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" value={formData.message} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formCheckbox" className="ego-field-label-white mt-1">
                <Form.Check
                    name="concent"
                    className="mt-3 big-checkbox"
                    type="checkbox"
                    checked={formData.concent}       
                    onChange={handleChange}             
                    label={
                        <>
                            * Ich stimme zu, dass meine Daten zur Bearbeitung meiner Anfrage verwendet werden. Mehr Informationen finden Sie in unserer <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"><span className="ego-field-label-white ego-link-dark">Datenschutzerklärung</span></a>.
                        </>
                    }     
                    required               
                />
            </Form.Group>

            <div className="align-self-center zIndex-1 m-5">
                {isSubmitting ? (
                    <div className="spinner-overlay">
                        <Spinner animation="border" role="status" variant="white">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    <ButtonTextPromo
                        colorClass="bg-danger"
                        btnSize="sm"
                        text="Demo anfordern"
                        type={"submit"}
                        inverse
                    />)}
            </div>
        </Form>
    );
}