import {Card, Container} from 'react-bootstrap';

export interface BannerBlockPromoProps {
    data: any
}

export default function BannerBlockPromo({data}: BannerBlockPromoProps) {
    const topBlock = "00-top-block";
    return (
        <section className="promo-banner-block">
            <div className="container-fluid h-100 position-relative">
                <Container>
                    {/* <div className="text-start text-black opacity-75 card promo-banner-block-text">
                        <p className="m-0 p-3">{data["00-top-block"].intro}</p>
                    </div> */}
                    <Card className="text-start text-black opacity-75 promo-banner-block-text" style={{ width: '100%', maxWidth: '30rem' }}>
                        <Card.Header> 
                            <Card.Title className="h3 ego-text-subtitle">{data[topBlock].title}</Card.Title> 
                            <Card.Subtitle>{data[topBlock].subtitle}</Card.Subtitle>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text className="ego-text-normal">{data[topBlock].body}</Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </section>
    )
}