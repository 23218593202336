import { Col, Modal, Row } from "react-bootstrap";
import { processAndHighlightEmails } from "../../pages/helpers";

type ModalErrorDialogProps = {
    show: boolean,
    onClose: () => void,
    tooManyRequests: boolean,
    badRequest: boolean,
    data: any
}

export default function ModalErrorDialog({show, onClose, tooManyRequests, badRequest, data}: ModalErrorDialogProps) {
    let errorMessage: string = data.generalError;

    if (tooManyRequests) errorMessage = data.tooManyRequests;
    if (badRequest) errorMessage = data.badRequest;

    return(
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            dialogClassName="modal-price-details"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row className="w-100">
                    <Col xs={12}>
                        <Modal.Title><h2 className="ego-text-name mb-0">{data.title}</h2></Modal.Title>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body> {processAndHighlightEmails(errorMessage)} </Modal.Body>
        </Modal>
);
}