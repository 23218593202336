import { Link } from 'react-router-dom';


/* eslint-disable max-len */

export default function EpulseLogoPromo() {    
    return (
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <h1 className="ego-font-moderno text-success logo-title">
            <div className="main-logo">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    viewBox="0 0 362 362"
                    style={{
                        shapeRendering: 'geometricPrecision',
                        textRendering: 'geometricPrecision',
                        fillRule: 'evenodd',
                        clipRule: 'evenodd',
                    }}
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <g><path style={{ opacity: '0.97' }} fill="#f0b3a3" d="M 176.5,3.5 C 179.226,3.26386 181.559,4.09719 183.5,6C 213.514,32.5606 221.348,64.7272 207,102.5C 201.43,112.738 193.763,121.071 184,127.5C 183.98,104.079 183.313,80.7454 182,57.5C 178.606,55.7253 175.94,56.392 174,59.5C 173.833,81.8408 173.333,104.174 172.5,126.5C 156.423,116.226 146.923,101.56 144,82.5C 139.935,49.8237 150.769,23.4904 176.5,3.5 Z" /></g>
                    <g><path style={{ opacity: '0.953' }} fill="#f0b3a3" d="M 288.5,51.5 C 293.898,52.0045 299.232,52.8378 304.5,54C 310.448,94.1055 295.448,123.439 259.5,142C 246.621,147.281 233.621,148.115 220.5,144.5C 236.153,128.347 251.986,112.347 268,96.5C 268.645,92.1496 266.812,89.9829 262.5,90C 246.653,106.014 230.653,121.847 214.5,137.5C 212.931,136.286 212.098,134.619 212,132.5C 210.176,102.314 222.009,79.1475 247.5,63C 260.388,55.8653 274.055,52.032 288.5,51.5 Z" /></g>
                    <g><path style={{ opacity: '0.954' }} fill="#f0b3a3" d="M 56.5,53.5 C 96.062,50.1892 124.562,66.1892 142,101.5C 146.894,113.663 147.56,125.997 144,138.5C 127.862,123.362 112.029,107.862 96.5,92C 93.4904,89.7781 90.9904,90.2781 89,93.5C 88.4054,94.8989 88.5721,96.2322 89.5,97.5C 105.5,113.167 121.5,128.833 137.5,144.5C 121.181,149.453 105.514,147.62 90.5,139C 59.9946,119.481 47.1612,91.6472 52,55.5C 53.6225,54.9011 55.1225,54.2344 56.5,53.5 Z" /></g>
                    <g><path style={{ opacity: '0.957' }} fill="#73dfe8" d="M 169.5,143.5 C 201.977,141.81 217.143,157.143 215,189.5C 207.64,211.274 192.474,220.441 169.5,217C 147.726,209.64 138.559,194.474 142,171.5C 146.016,156.985 155.183,147.652 169.5,143.5 Z M 174.5,170.5 C 184.988,169.487 189.488,174.154 188,184.5C 185.081,190.025 180.581,191.858 174.5,190C 168.975,187.081 167.142,182.581 169,176.5C 170.36,173.977 172.193,171.977 174.5,170.5 Z" /></g>
                    <g><path style={{ opacity: '0.971' }} fill="#f0b3a3" d="M 53.5,145.5 C 74.701,142.569 94.0343,147.069 111.5,159C 116.912,163.741 121.579,169.074 125.5,175C 102.833,175.333 80.1667,175.667 57.5,176C 54.392,177.94 53.7253,180.606 55.5,184C 78.4194,185.147 101.419,185.98 124.5,186.5C 114.226,202.577 99.5595,212.077 80.5,215C 47.947,219.16 21.7803,208.326 2,182.5C 1.33333,181.167 1.33333,179.833 2,178.5C 14.8927,160.805 32.0593,149.805 53.5,145.5 Z" /></g>
                    <g><path style={{ opacity: '0.971' }} fill="#f0b3a3" d="M 277.5,145.5 C 309.681,142.047 335.514,153.047 355,178.5C 355.667,179.833 355.667,181.167 355,182.5C 335.26,208.316 309.093,219.149 276.5,215C 257.065,212.122 242.065,202.455 231.5,186C 254.167,185.667 276.833,185.333 299.5,185C 302.379,183.334 303.212,180.834 302,177.5C 301.25,176.874 300.416,176.374 299.5,176C 277.159,175.833 254.826,175.333 232.5,174.5C 243.114,158.026 258.114,148.36 277.5,145.5 Z" /></g>
                    <g><path style={{ opacity: '0.952' }} fill="#f0b3a3" d="M 113.5,211.5 C 121.534,211.302 129.534,211.635 137.5,212.5C 137.649,213.552 137.483,214.552 137,215.5C 120.792,231.374 104.959,247.541 89.5,264C 90.6605,267.51 92.9939,268.844 96.5,268C 112.347,251.986 128.347,236.153 144.5,220.5C 149.934,247.693 141.934,270.193 120.5,288C 101.409,303.19 79.742,308.857 55.5,305C 48.0135,272.805 57.3468,246.138 83.5,225C 92.7353,218.544 102.735,214.044 113.5,211.5 Z" /></g>
                    <g><path style={{ opacity: '0.954' }} fill="#f0b3a3" d="M 230.5,211.5 C 268.649,214.473 292.815,234.473 303,271.5C 305.857,283.172 306.023,294.839 303.5,306.5C 263.985,311.162 234.818,296.162 216,261.5C 209.957,248.031 209.124,234.364 213.5,220.5C 229.292,236.793 245.292,252.959 261.5,269C 266.152,269.342 267.986,267.176 267,262.5C 250.804,246.638 234.971,230.471 219.5,214C 223.28,213.082 226.946,212.249 230.5,211.5 Z" /></g>
                    <g><path style={{ opacity: '0.97' }} fill="#f0b3a3" d="M 172.5,233.5 C 173.02,256.751 173.854,280.084 175,303.5C 178.394,305.275 181.06,304.608 183,301.5C 183.167,279.159 183.667,256.826 184.5,234.5C 200.577,244.774 210.077,259.44 213,278.5C 217.209,309.557 207.376,335.057 183.5,355C 181.933,356.076 180.266,356.909 178.5,357.5C 164.206,348.694 154.04,336.361 148,320.5C 142,301.5 142,282.5 148,263.5C 153.067,250.936 161.234,240.936 172.5,233.5 Z" /></g>
                </svg>
            </div>
            {' '}
            energiepulse
        </h1>
        </Link>
    );
}
