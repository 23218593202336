import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import PromoLandingPage from './pages/PromoLandingPage';
import IndexLayoutPromo from "./components/index-layout-promo";
import RequestDemoPage from './pages/RequestDemoPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Impressum from './pages/ImpressumPage';

function App() {
  return (
      <Routes>
          <Route element={<IndexLayoutPromo />}>
              <Route index element={<PromoLandingPage />} />              
              <Route path="request-demo" element={<RequestDemoPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="impressum" element={<Impressum />} />
          </Route>
      </Routes>
  );
}

export default App;
