import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const isAppInsightsInitialized = process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING !== undefined;


if (process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING) {
  console.info('AIs configuration found');
} else {
  console.warn('No AIs configuration found');
}

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    // eslint-disable-next-line max-len
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});


export { appInsights, reactPlugin, isAppInsightsInitialized };
