import { Container, Row, Col, Modal } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import { useState } from 'react';
import { fullVersion, oxanaLinkedIn } from "../pages/PromoLandingPage";
import { processAndHighlightEmails } from "../pages/helpers";
import { Link } from "react-router-dom";

export default function FooterPromo() {
    const [editMainInfo, setEditMainInfo] = useState(false);
    const handleClose = () => setEditMainInfo(false);
    const data = require('../pages/disclaimer-page-texts.json');
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });
   
    return (
        <footer className="mt-auto">
            <div className="bg-dark text-white container-fluid h-100 position-relative">
                <Container>
                    <Row className="py-3">
                        <Col xs={2} md={1} lg={1}>
                            <a href={oxanaLinkedIn} target="_blank" className="text-white fs-4"><span className="bi bi-linkedin"></span></a>
                        </Col>
                        <Col xs={10} md={6} lg={4} className="ps-0 d-flex align-items-center justify-content-between">
                            <div className="text-white float-start promo-footer-link mb-0" onClick={() => setEditMainInfo(true)}>
                                Disclaimer
                            </div>        
                            <Link className="text-white float-end" to="/privacy-policy">Datenschutzerklärung</Link>
                            <Link className="text-white float-end" to="/impressum">Impressum</Link>                                
                        </Col>
                        <Col xs={12} md={12} lg={7} className={isMobile ? "align-self-center" : "align-self-center ps-0"}>
                            <div className={isMobile ? "text-white mb-0 text-start" : "text-white mb-0 text-end"}>@ Copyright 2024 energiepulse.de - Some rights reserved</div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                show={editMainInfo}
                onHide={handleClose}
                size="lg"
                dialogClassName="modal-price-details"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Row className="w-100">
                        <Col xs={12}>
                            <Modal.Title><h2 className="ego-text-name mb-0">{data.title}</h2></Modal.Title>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <p>{processAndHighlightEmails(data["body"]["part1_de"])}</p>
                    <p>{processAndHighlightEmails(data["body"]["part2_de"])}</p>
                </Modal.Body>
            </Modal>
        </footer>
    )
}