import { Col, Modal, Row } from "react-bootstrap";

type ModalSuccessDialogProps = {
    show: boolean,
    onClose: () => void,
    name: string,
    data: any
}

export default function ModalSuccessDialog({show, onClose, name, data}: ModalSuccessDialogProps) {
    return(
        <Modal
            show={show}
            onHide={onClose}
            size="lg"
            dialogClassName="modal-price-details"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Row className="w-100">
                    <Col xs={12}>
                        <Modal.Title><h2 className="ego-text-name mb-0">{data.title}</h2></Modal.Title>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <p>{`${data["message"].start} ${name},`}</p>
                <p>{data["message"].section1}</p>
                <p>{data["message"].section2}</p>
                <p>{data["message"].section3}</p>
                <p>{data["message"].section4}</p>
                <p>
                    <div>{data["message"].end}</div>
                    <div>{data["message"].signature}</div>
                </p>
            </Modal.Body>
        </Modal>
    );
}