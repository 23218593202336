import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import BalanceImage from "../styles/images/04. block-customer-pros/icons/balance-white.png";
import ChatbotImage from "../styles/images/04. block-customer-pros/icons/chatbot-white.png";
import CommunitiesImage from "../styles/images/04. block-customer-pros/icons/communities-white.png";
import ContractImage from "../styles/images/04. block-customer-pros/icons/contract-white-v2.png";
import CustomerImage from "../styles/images/04. block-customer-pros/icons/customer-white.png";
import GlobalImage from "../styles/images/04. block-customer-pros/icons/global-white.png";
import HomeImage from "../styles/images/04. block-customer-pros/icons/home-white.png";
import PersonalImage from "../styles/images/04. block-customer-pros/icons/personal-credentials-white.png";

export default function CustomerBenefitsBlockPromo({data}: BannerBlockPromoProps) {
    return (
        <section className="benefits-block">
            <div className="bg-white container-fluid h-100 position-relative">
                <Container>
                    <div className="ego-stack-sm-gap mb-3 text-center vstack">
                        {/* <h2 className="ego-text-title text-center">{data["04-block-customer-pros"].title}</h2> */}
                        <div className="promo-data-table">
                            <div className="promo-data-table-header">
                                <div className="promo-data-table-block left-block">
                                    <h3 className="card-title ego-text-name text-success">{data["04-block-customer-pros"]["first-subtitle"]}</h3>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <h3 className="card-title ego-text-name text-danger">{data["04-block-customer-pros"]["second-subtitle"]}</h3>
                                </div>
                            </div>
                            <hr/>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro1"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["04-block-customer-pros"]["online-shop"]["pro1"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-success">
                                            <Image src={BalanceImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-danger">
                                            <Image src={ContractImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro5"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro5"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro2"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro2"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-success">
                                            <Image src={ChatbotImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-danger">
                                            <Image src={HomeImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro6"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro6"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro3"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro3"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-success">
                                            <Image src={CustomerImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-danger">
                                            <Image src={CommunitiesImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro7"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro7"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro4"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro4"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-success">
                                            <Image src={GlobalImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-danger">
                                            <Image src={PersonalImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["04-block-customer-pros"]["online-shop"]["pro8"]["title"]}</h3>
                                            <p className="ego-text-description mb-0">{data["04-block-customer-pros"]["online-shop"]["pro8"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}