import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import VendorSuiteImage from "../styles/images/05. block-vendor-pros/energiepulse-vendor-suite.png";
import VendorImage from "../styles/images/05. block-vendor-pros/vendor.png";
import BlockTitle from "./block-title";

export default function VendorSuiteBlockPromo({data}: BannerBlockPromoProps) {
    return (
        <section className="promo-marketplace-block">
            <div className="container-fluid h-100 position-relative bg-light">
                <Container className="position-relative">
                    <BlockTitle title={data["05-block-vendor-pros"].title} />
                    <Image src={VendorImage} className="promo-marketplace-image right-pos" />
                    <Image src={VendorSuiteImage} fluid className="object-fit-cover w-100 h-100" />
                </Container>
            </div>
        </section>
    )
}