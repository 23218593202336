import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from 'react-bootstrap';
import AboutServicesBlockPromoImage from './../styles/images/01. block-products/energy-products.png';
// import AboutServicesBlockPromoImageJpg from './../styles/images/01. block-products/energy-products.jpg';
import BlockTitle from "./block-title";

export default function ProductsBlockPromo({data}: BannerBlockPromoProps) {
    return (
        <section className="about-services-block">
            <div className="container-fluid h-100 position-relative">
                <Container>
                    <BlockTitle title={data["01-block-products"].title} />
                    <p className="ego-text-description mb-4">{data["01-block-products"].body}</p>
                    <Image src={AboutServicesBlockPromoImage} fluid className="object-fit-cover w-100 h-100" />
                </Container>
            </div>
        </section>
    )
}