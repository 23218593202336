export default function LetsTalkBlock() {
    const data = require('../../pages/demo-request-texts.json');
    return(
        <>
            <h2 className="ego-text-title my-3">{data.title}</h2>
            <p className="ego-text-description">
                {data.introduction}
            </p>  
            <p className="ego-text-name pt-3">
                {data.subtitle}
            </p>
            <p className="ego-text-description">
                {data["agenda"].summary}
            </p>   
            <ul>
                <li className="ego-text-description">
                    <strong>{data["agenda"]["firstBullet"].title}</strong>
                    {' '}
                    {data["agenda"]["firstBullet"].point}
                </li>
                <li className="ego-text-description">
                    <strong>{data["agenda"]["secondBullet"].title}</strong>
                    {' '}
                    {data["agenda"]["secondBullet"].point}
                </li>
                <li className="ego-text-description">
                    <strong>{data["agenda"]["thirdBullet"].title}</strong>
                    {' '}
                    {data["agenda"]["thirdBullet"].point}
                </li>
            </ul>
            <p className="ego-text-description pt-3">
                {data["agenda"].contactUs}
            </p>   
        </>
    );
}