import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import AnalyticsImage from "../styles/images/05. block-vendor-pros/icons/analytics-white.png";
import CustumerImage from "../styles/images/05. block-vendor-pros/icons/custumer-white.png";
import DataReportImage from "../styles/images/05. block-vendor-pros/icons/data-report-white.png";
import IntegrationImage from "../styles/images/05. block-vendor-pros/icons/integration-white.png";
import PrinterImage from "../styles/images/05. block-vendor-pros/icons/printer-white.png";
import StatisticsImage from "../styles/images/05. block-vendor-pros/icons/statistics-white.png";
import SupportImage from "../styles/images/05. block-vendor-pros/icons/support-white.png";
import TechnologyImage from "../styles/images/05. block-vendor-pros/icons/technology-white.png";

export default function VendorBenefitsBlockPromo({data}: BannerBlockPromoProps) {
    return (
        <section className="benefits-block">
            <div className="container-fluid h-100 position-relative">
                <Container>
                    <div className="ego-stack-sm-gap mb-3 text-center vstack">
                        {/* <h2 className="ego-text-title text-center">{data["05-block-vendor-pros"].title}</h2> */}
                        <div className="promo-data-table">
                            <div className="promo-data-table-header justify-content-center">
                                <h3 className="card-title ego-text-name text-success text-warning">{data["05-block-vendor-pros"]["subtitle"]}</h3>
                            </div>
                            <hr/>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block first-ch">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro1"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro1"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={TechnologyImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block first-ch">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={DataReportImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro5"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro5"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro2"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro2"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={CustumerImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={IntegrationImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro6"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro6"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro3"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro3"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={StatisticsImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={PrinterImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro7"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro7"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block left-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-text-block pe-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro4"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro4"]["body"]}</p>
                                        </div>
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={AnalyticsImage} />
                                        </div>
                                    </div>
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon bg-warning">
                                            <Image src={SupportImage} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-primary">{data["05-block-vendor-pros"]["online-shop"]["pro8"]["title"]}</h3>
                                            <p className="mb-0 ego-text-description">{data["05-block-vendor-pros"]["online-shop"]["pro8"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}