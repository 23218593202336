import HeaderPromo from "../components/header-promo";
import BannerBlockPromo from "../components/banner-block-promo";
import IdeaBlockPromo from "../components/idea-block-promo";
import MarketplaceBlockPromo from "../components/marketplace-block-promo";
import AboutUsBlockPromo from "../components/about-us-block-promo";
import ProductsBlockPromo from '../components/products-block-promo';
import OldPortalsBlockPromo from '../components/old-portals-block-promo';
import CustomerBenefitsBlockPromo from "../components/customer-benefits-block-promo";
import VendorSuiteBlockPromo from "../components/vendor-suite-block-promo";
import VendorBenefitsBlockPromo from "../components/vendor-benefits-block-promo";
import RoadmapStepsBlockPromo from "../components/roadmap-steps-block-promo";
import MainComponentsBlockPromo from "../components/main-components-block-promo";

export const oxanaLinkedIn: string = "https://www.linkedin.com/in/oxana-glazunova/";
export const fullVersion: boolean = false; 

export default function PromoLandingPage() {
    const data = require('../pages/promo-page-texts.json');

    return (
        <div className="promo-page-v1">
            <main role="main">
                <div className="ego-stack-page-sections-gap vstack">
                    <BannerBlockPromo data={data}/>
                    <ProductsBlockPromo data={data} />
                    <OldPortalsBlockPromo data={data} />
                    <IdeaBlockPromo data={data} />
                    {fullVersion && <MarketplaceBlockPromo data={data} />}
                    {fullVersion && <CustomerBenefitsBlockPromo data={data} />}
                    {fullVersion && <VendorSuiteBlockPromo data={data} />}
                    {fullVersion && <VendorBenefitsBlockPromo data={data} />}
                    <MainComponentsBlockPromo data={data} />
                    <RoadmapStepsBlockPromo data={data} />
                    <AboutUsBlockPromo data={data} />
                </div>
            </main>
        </div>
    );
};
