import { Outlet } from "react-router-dom";
import PromoLandingPage from "../pages/PromoLandingPage";
import FooterPromo from "./footer-promo";
import HeaderPromo from "./header-promo";

export default function IndexLayoutPromo() {
    return (
        <>
            <HeaderPromo />
            <Outlet />
            <FooterPromo />
        </>
    )
}