import { Container, Row } from "react-bootstrap";


export default function PrivacyPolicyPage() {
    return (
        <div className="promo-page-v1">
            <main role="main">
                <section>
                    <Container>
                        <Row>
                            <h2 className="ego-text-title">Datenschutzerklärung für Demo-Anfragen</h2>

                            <h3 className="ego-text-name">
                                1. Einleitung
                            </h3>
                            <p>
                                Diese Datenschutzerklärung gilt für die Datenverarbeitung durch energiepulse.de, 
                                im Folgenden "wir" oder "uns" genannt, im Rahmen der Demo-Anfragen auf unserer Website. Wir nehmen den Schutz Ihrer persönlichen Daten ernst und behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>

                            <h3 className="ego-text-name">
                                2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung
                            </h3>
                            <p>Bei der Anforderung einer Demo über unsere Website erheben wir folgende Informationen:</p>
                            <p className="mb-0">
                                <ul>
                                    <li>E-Mail-Adresse</li>
                                    <li>Vorname</li>
                                    <li>Nachname</li>
                                    <li>Firmenname</li>
                                    <li>Berufsbezeichnung</li>
                                    <li>Telefonnummer</li>
                                    <li>Ihre Nachricht</li>
                                </ul>
                            </p>
                            <p>Diese Daten werden erhoben, um:</p>
                            <p className="mb-0">
                                <ul>
                                    <li>Ihre Anfrage für eine Demo zu bearbeiten und zu beantworten.</li>
                                    <li>Ihnen Informationen und Angebote zu unseren Produkten zur Verfügung zu stellen (Direktmarketing).</li>
                                    <li>Die Sicherheit und Stabilität der Website zu gewährleisten.</li>
                                </ul>
                            </p>

                            <h3 className="ego-text-name">
                                3. Weitergabe von Daten an Dritte
                            </h3>
                            <p>Ihre persönlichen Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben, es sei denn, dies ist gesetzlich erlaubt oder erforderlich.</p>

                            <h3 className="ego-text-name">
                                4. Datensicherheit
                            </h3>
                            <p>Wir verwenden geeignete technische und organisatorische Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen.</p>

                            <h3 className="ego-text-name">
                                5. Keine Verwendung von Cookies
                            </h3>
                            <p>
                                Diese Webseite setzt keine Cookies ein. Das bedeutet, dass während Ihres Besuches auf unserer Webseite
                                keine persönlichen Daten durch Cookies gesammelt oder gespeichert werden.
                            </p>
                            
                            <h3 className="ego-text-name">
                                6. Betroffenenrechte
                            </h3>
                            <p>Sie haben das Recht:</p>
                            <p className="mb-0">
                                <ul>
                                    <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen;</li>
                                    <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
                                    <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;</li>
                                    <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen;</li>
                                    <li>gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen.</li>
                                </ul>
                            </p>

                            <h3 className="ego-text-name">
                                7. Datenschutzbeauftragter
                            </h3>
                            <p>Für Fragen zum Datenschutz steht Ihnen unser Datenschutzbeauftragter unter <span className="ego-link-dark">legal@energiepulse.de</span> zur Verfügung.</p>

                            <h3 className="ego-text-name">
                                8. Aktualität und Änderung dieser Datenschutzerklärung
                            </h3>
                            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand 19.02.2024. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.</p>

                        </Row>
                    </Container>
                </section>
            </main>
        </div>
    );
};