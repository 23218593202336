import Container from 'react-bootstrap/Container';
import EpulseLogoPromo from "./epulse-logo-promo";
import { ButtonTextPromo } from "./button-text-promo";
import { useLocation } from 'react-router-dom';

export default function HeaderPromo() {

    const location = useLocation();
    const hideButton = location.pathname === '/request-demo';

    return (
        <header className="bg-white sticky-top navbar-header promo-header">
            <Container className="d-table py-2">
                <span className="navbar-brand float-start">
                    <EpulseLogoPromo />
                </span>
                <span className="float-end mt-1">
                    {!hideButton && <ButtonTextPromo
                        colorClass="bg-danger"
                        btnSize="sm"
                        text="Demo anfordern"
                        href={"/request-demo"}
                    />}
                </span>
            </Container>
        </header>
    )
}