import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import IdeaInfoImage from "../styles/images/03. block-energiepulse-idea/energiepulse-idea.png";
import LampImage from "../styles/images/03. block-energiepulse-idea/lamp-with-arrow.png";
import IdeaInfoImageMobile from "../styles/images/03. block-energiepulse-idea/energiepulse-idea-mobile.png";

export default function IdeaBlockPromo({data}: BannerBlockPromoProps) {
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });

    return (
        <section className="promo-idea-block">
            <div className="container-fluid h-100 position-relative">
                <Container className="position-relative">
                    <Image src={LampImage} className="promo-idea-block-lamp" />
                    <div className="promo-idea-block-info bg-danger">
                        <p className="m-0">{data["03-block-energiepulse-idea"].idea}</p>
                    </div>
                    <Image src={isMobile ? IdeaInfoImageMobile : IdeaInfoImage} fluid className="object-fit-cover promo-idea-block-main-image h-100" />
                </Container>
            </div>
        </section>
    )
}