export enum Breakpoints {
  TABLET = '(max-width: 991.98px)',
  ONLY_TABLET = '(min-width : 768px) and (max-width: 991.98px)',
  REGULAR_MOBILE = '(max-width: 767.98px)',
  ONLY_REGULAR_MOBILE = '(min-width : 640px) and (max-width: 767.98px)',
  SMALL_MOBILE = '(max-width: 575.98px)',
  SMALL_DESKTOP = '(max-width: 1199.98px)',
  ONLY_SMALL_DESKTOP = '(min-width : 992px) and (max-width: 1199.98px)',
  BIG_DESKTOP = '(min-width: 1200px) and (max-width: 4000px)',
}

export const DEFAULT_CONSUMPTION: number = 3500;

export enum ScrollDisrections {
  UP = 'up',
  DOWN = 'down',
}
