
/**
 * Function to process and highlight email addresses in a given text.
 * @param text The text containing one or more email addresses to be highlighted.
 * @returns A React element with email addresses highlighted.
 */

export const processAndHighlightEmails = (text: string) => {
    // Regular expression to match email addresses
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    
    // Split the text by email addresses, keeping the emails in the result
    const parts = text.split(emailRegex);
  
    // Transform the parts into an array of JSX elements, highlighting the emails
    const processedText = parts.map((part, index) => {
      // Check if the part is an email address
      if (part.match(emailRegex)) {
        return <span key={index} className="ego-link-dark">{part}</span>;
      }
      return part; // Non-email parts are returned unchanged
    });
  
    return <>{processedText}</>;
  };