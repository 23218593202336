import { Link } from 'react-router-dom';
import { forwardRef } from 'react';
import './show-more-link.css';

export type ShowMoreLinkProps = {
  link: string,
  text: string,
  onClick?: (e: any) => void,
  onClickWithNavigation?: (e: any) => void,
  inverse?: boolean
};

export const ShowMoreLink = forwardRef((props: ShowMoreLinkProps, ref: any) => {
  if (!props.link || props.link === '') return null;

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (props.onClick) {
      props.onClick(e);
    }
  };

  const handleClickWithNavigation = (e: any) => {
    if (props.onClickWithNavigation) props.onClickWithNavigation(e);
  };

  const lengthOfText = props.text.length;
  const value = lengthOfText > 20;

  let isOtherDomain = false;
  if (props.link.indexOf('http') !== -1) {
    isOtherDomain = true;
  }
  return (
    <div className={`show-more float-start ${props.inverse ? 'inverse' : ''}`}>
      {
        // eslint-disable-next-line no-nested-ternary
        isOtherDomain
          ? (
            <a className="iconfont-wrapper float-start text-decoration-none text-dark" href={props.link} target="_blank" rel="noreferrer">
              <span className="icon-bg bg-danger" />
              <span className="p-2 bi bi-caret-right-fill" />
            </a>
          )
          : (props.onClick
            ? (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/interactive-supports-focus
              <a role="button" className="iconfont-wrapper float-start text-decoration-none text-dark" ref={ref} onClick={handleClick}>
                <span className="icon-bg bg-danger" />
                <span className="p-2 bi bi-caret-right-fill" />
              </a>
            )
            : (
              <Link className="iconfont-wrapper float-start text-decoration-none text-dark" to={props.link} onClick={handleClickWithNavigation}>
                <span className="icon-bg bg-danger" />
                <span className="p-2 bi bi-caret-right-fill" />
              </Link>
            ))
      }
      <p className="link float-start fs-6 px-2 mb-0">
        {
          // eslint-disable-next-line no-nested-ternary
          isOtherDomain
            ? (
              <a href={props.link} className={`text-decoration-none text-dark ${props.inverse ? 'text-white' : 'text-black'} ${value ? 'description' : ''}`}>
                <strong>{props.text}</strong>
              </a>
            )
            : (props.onClick
              ? (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
                <a role="button" className={`text-decoration-none text-dark ${props.inverse ? 'text-white' : 'text-black'}`} ref={ref} onClick={handleClick}>
                  <strong>{props.text}</strong>
                </a>
              )
              : (
                <Link
                  to={props.link}
                  onClick={handleClickWithNavigation}
                  className={`text-decoration-none text-dark ${props.inverse ? 'text-white' : 'text-black'} ${value ? 'description' : ''}`}
                >
                  <strong>{props.text}</strong>
                </Link>
              )
            )
        }
      </p>
    </div>
  );
});
