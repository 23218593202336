import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import Step1 from "../styles/images/06. block-roadmap/step-1.png";
import Step2 from "../styles/images/06. block-roadmap/step-2.png";
import Step3 from "../styles/images/06. block-roadmap/step-3.png";
import Step4 from "../styles/images/06. block-roadmap/step-4.png";
import Step5 from "../styles/images/06. block-roadmap/step-5.png";
import Step6 from "../styles/images/06. block-roadmap/step-6.png";
import Step1Info from "../styles/images/06. block-roadmap/component-vendor-suite.png";
import Step2Info from "../styles/images/06. block-roadmap/component-customer-account.png";
import Step3Info from "../styles/images/06. block-roadmap/component-online-shop.png";
import Step1InfoMobile from "../styles/images/06. block-roadmap/component-vendor-suite-2.png";
import Step2InfoMobile from "../styles/images/06. block-roadmap/component-customer-account-2.png";
import Step3InfoMobile from "../styles/images/06. block-roadmap/component-online-shop-2.png";
import BlockTitle from "./block-title";

export default function RoadmapStepsBlockPromo({data}: BannerBlockPromoProps) {
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });

    return (
        <section className="benefits-block roadmap-block">
            <div className="bg-primary text-white container-fluid h-100 position-relative">
                <Container>
                    <BlockTitle title={data["07-block-roadmap"].title} />
                    <div className="ego-stack-sm-gap mb-3 text-center vstack">
                        <div className="promo-data-table one-column-table">
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-step-info">
                                    <Image src={isMobile ? Step1InfoMobile : Step1Info} className="w-100" />
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step1} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step1"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step1"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-step-info">
                                    <Image src={isMobile ? Step2InfoMobile : Step2Info} className="w-100" />
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step2} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step2"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step2"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-step-info">
                                    <Image src={isMobile ? Step3InfoMobile : Step3Info} className="w-100" />
                                </div>
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step3} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step3"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step3"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step4} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step4"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step4"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step5} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step5"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step5"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="promo-data-table-row">
                                <div className="promo-data-table-block right-block">
                                    <div className="promo-data-table-block-content">
                                        <div className="promo-data-table-icon">
                                            <Image src={Step6} />
                                        </div>
                                        <div className="promo-data-table-text-block ps-3">
                                            <h3 className="ego-text-subtitle text-white">{data["07-block-roadmap"]["roadmap"]["step6"]["title"]}</h3>
                                            <p className="mb-0 text-white ego-text-description">{data["07-block-roadmap"]["roadmap"]["step6"]["body"]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}