import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import MarketplaceSchemaImage from "../styles/images/04. block-customer-pros/energypulse-customer-marketplace-title.png";
import MarketplaceImage from "../styles/images/04. block-customer-pros/marketplace.png";
import BlockTitle from "./block-title";

export default function MarketplaceBlockPromo({data}: BannerBlockPromoProps) {
    return (
        <section className="promo-marketplace-block">
            <div className="container-fluid h-100 position-relative bg-light">
                <Container className="position-relative">
                    <BlockTitle title={data["04-block-customer-pros"].title} />
                    <Image src={MarketplaceImage} className="promo-marketplace-image" />
                    <Image src={MarketplaceSchemaImage} fluid className="object-fit-cover w-100 h-100" />
                </Container>
            </div>
        </section>
    )
}