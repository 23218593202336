import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Image} from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import RoadmapSchemeImage from "../styles/images/06. block-roadmap/energiepulse-components.png";
import RoadmapSchemeImageMobile from "../styles/images/06. block-roadmap/energiepulse-components-mobile.png";
import BlockTitle from "./block-title";

export default function MainComponentsBlockPromo({data}: BannerBlockPromoProps) {
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });
    const isSmallMobile = useMediaQuery({ query: Breakpoints.SMALL_MOBILE });

    return (
        <section className="promo-marketplace-block main-comp-block">
            <div className="bg-light container-fluid h-100 position-relative">
                <Container className="position-relative">
                    <BlockTitle title={data["06-block-components"].title} />
                    <div className="w-100 h-100 position-relative">
                        <div className={isSmallMobile ? "main-comp-block-text position-relative" : "main-comp-block-text position-absolute top-0 end-0"}>
                            <p className="ego-text-description mb-0">{data["06-block-components"].body}</p>
                        </div>
                        <Image src={isMobile ? RoadmapSchemeImageMobile : RoadmapSchemeImage} fluid className="roadmap-image-promo object-fit-cover w-100 h-100" />
                    </div>
                </Container>
            </div>
        </section>
    )
}