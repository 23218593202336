import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Row, Col, Image} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import TeamCycleImage from './../styles/images/07. block-team/team-cycle.png';
import TeamWorkImage from './../styles/images/07. block-team/team-at-work.png';
import {ButtonTextPromo} from "./button-text-promo";
import { oxanaLinkedIn } from "../pages/PromoLandingPage";

export default function AboutUsBlockPromo({data}: BannerBlockPromoProps) {
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });
    const isOnlyTablet = useMediaQuery({ query: Breakpoints.ONLY_TABLET });
    const isTabletOrMobile = useMediaQuery({ query: Breakpoints.TABLET });

    return (
        <section className="about-us-block">
            <div className="container-fluid h-100 position-relative pt-3">
                <Container>
                    <Row>
                        <Col md={6} lg={8} className="align-self-center">
                            <h2 className="ego-text-title">{data["08-block-team"].title}</h2>
                            {/* <p className="mb-2 ego-text-description">{data["08-block-team"]["left-section-1"]}</p> */}
                            <p className="mb-2 ego-text-description">
                                Gestartet im Jahr 2022, vereint unser dynamisches Startup-Team, 
                                angeführt von <a href={oxanaLinkedIn} target="_blank">Oxana Glazunova</a> als Gründerin, 
                                umfassende Expertise sowohl in der Energiebranche als auch in Frontend- und Backend-Entwicklung, 
                                Cloud-Lösungsarchitektur und Datensicherheit.
                            </p>
                            <p className="mb-0 ego-text-description">{data["08-block-team"]["left-section-2"]}</p>
                        </Col>
                        <Col md={6} lg={4} className="align-self-center">
                            {
                                !isMobile && <Image src={TeamCycleImage} fluid className="w-100" />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={6} lg={4} className="align-self-center">
                            <Image src={TeamWorkImage} fluid className="w-100" />
                        </Col>
                        <Col xs={12} md={6} lg={8} className="align-self-center">
                            <p className="mb-0 ego-text-description">{data["08-block-team"]["right-section"]}</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="container-fluid h-100 position-relative bg-warning">
                <Container>
                    <h3 className="ego-text-name">{data["08-block-team"]["demo-request"]["title"]}</h3>
                    <Row>
                        <Col xs={12} md={12} lg={9}>
                            <p className="mb-0 ego-text-description">{data["08-block-team"]["demo-request"]["body"]}</p>
                        </Col>
                        <Col xs={12} md={12} lg={3} className={isTabletOrMobile ? 'd-flex flex-wrap mt-3 zIndex-1' : 'align-self-center zIndex-1'}>
                            <ButtonTextPromo
                                colorClass="bg-danger"
                                className={isTabletOrMobile ? "mw-125px mx-auto" : ""}
                                btnSize="md"
                                text="Demo anfordern"
                                href={"/request-demo"}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    )
}