
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import DemoRequestForm from "../components/demo-request/demo-request-form";
import ModalErrorDialog from "../components/demo-request/modal-error-dialog";
import ModalSuccessDialog from "../components/demo-request/modal-success-dialog";
import LetsTalkBlock from '../components/demo-request/lets-talk-block';
import { ShowMoreLink } from '../components/demo-request/show-more-link/show-more-link';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';

export default function RequestDemoPage() {
    const data = require('../pages/demo-request-texts.json');
    const isTabletOrMobile = useMediaQuery({ query: Breakpoints.TABLET });

    // const [markdownText, setMarkdownText] = useState<string>(data["100-demo-request"].content);
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const [tooManyRequests, setTooManyRequests] = useState<boolean>(false);
    const [badRequest, setBadRequest] = useState<boolean>(false);
    const [requesterName, setRequesterName] = useState<string>('');

    const navigate = useNavigate();

    const handleSuccessDialogClose = () => {
        setShowDialog(false);
        navigate('/');
    }

    const handleErrorDialogClose = () => {
        setShowErrorDialog(false);
    }

    const handleBadRequest = (state: boolean) => {
        setBadRequest(state);
        setShowErrorDialog(state);
    }

    const handleRequestSubmit = (name: string, state: boolean) => {
        setShowDialog(state);
        setRequesterName(name);
    }
    
    return (
        <div className="promo-page-v1">
            <main role="main">
                <section>
                    <Container>
                        <Row>
                            <Col md={12} lg={8} className={isTabletOrMobile ? 'pb-3' : ''}>
                                <LetsTalkBlock />
                                {/* <div className="ego-text-title">
                                    <ReactMarkdown>{markdownText}</ReactMarkdown>
                                </div> */}
                            </Col>
                            <Col md={12} lg={4} className="demo-request-form">
                                <DemoRequestForm
                                    onBadRequest={handleBadRequest}
                                    onError={setShowErrorDialog}
                                    onSuccess={handleRequestSubmit}
                                    onTooManyRequests={setTooManyRequests} />
                            </Col>
                        </Row>
                        <Row className={isTabletOrMobile ? 'mt-5' : 'mt-3'} >
                            <Col className='px-0'>
                                <ShowMoreLink text='Zur Startseite' link='/'/> 
                            </Col>
                        </Row>

                        <ModalSuccessDialog
                            show={showDialog}
                            onClose={handleSuccessDialogClose}
                            name={requesterName}
                            data={data["success"]} />

                        <ModalErrorDialog
                            show={showErrorDialog}
                            onClose={handleErrorDialogClose}
                            badRequest={badRequest}
                            tooManyRequests={tooManyRequests}
                            data={data["error"]} />
                        
                    </Container>
                </section>
            </main>
        </div>
    );
}
