import {BannerBlockPromoProps} from "./banner-block-promo";
import {Container, Row, Col, Image} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Breakpoints } from '../tools/constants-and-types';
import OnlineShopImage from "../styles/images/02. block-old-portals/online-shop-v2.png";
import Check24Image from "../styles/images/02. block-old-portals/check-24-v2.png";
import PortalImage from "../styles/images/02. block-old-portals/customer-portal-v2.png";
import Devider1 from "../styles/images/02. block-old-portals/online-shop-bracket.png";
import Devider2 from "../styles/images/02. block-old-portals/customer-portal-bracket.png";
import Devider3 from "../styles/images/02. block-old-portals/check-24-bracket.png";
import BlockTitle from "./block-title";

export default function OldPortalsBlockPromo({data}: BannerBlockPromoProps) {
    const isMobile = useMediaQuery({ query: Breakpoints.REGULAR_MOBILE });
    const textBlock = "02-block-old-portals";
    return (
        <section className="aviable-services-block">
            <div className="bg-primary text-white container-fluid h-100 position-relative">
                <Container>
                    <BlockTitle title={data[textBlock].title} />
                        {
                            isMobile ?
                                <Row className="justify-content-center align-items-end">
                                    <Col xs={8} className="align-items-end"><h2 className="ego-text-name mb-0">{data[textBlock]["online-shop"].title}</h2></Col>
                                    <Col xs={4}><Image src={OnlineShopImage} className="object-fit-cover w-100" /></Col>
                                    <Col xs={12} className="mt-3"><p className="ego-text-description">{data[textBlock]["online-shop"].body}</p></Col>
                                </Row>
                                :
                                <Row className="justify-content-center">
                                    <Col md={3} lg={2} className="d-flex flex-wrap align-items-center"><Image src={OnlineShopImage} className="object-fit-cover w-100" /></Col>
                                    <Col md={9} lg={10}>
                                        <h2 className="ego-text-name">{data[textBlock]["online-shop"].title}</h2>
                                        <p className="ego-text-description">{data[textBlock]["online-shop"].body}</p>
                                    </Col>
                                </Row>
                        }
                    <Row>
                        {
                            isMobile ?
                                <Col md={12} className="mb-2"><Image src={Devider1} fluid className="w-100" /></Col>
                                :
                                <Col md={12} className="mt-5 mb-4"><Image src={Devider1} fluid className="object-fit-cover w-100 h-100" /></Col>
                        }
                    </Row>
                    {
                        isMobile ?
                            <Row className="justify-content-center align-items-end">
                                <Col xs={4}><Image src={Check24Image} className="object-fit-cover w-100" /></Col>
                                <Col xs={8} className="align-items-end text-end"><h2 className="ego-text-name mb-0">{data[textBlock]["check-24"].title}</h2></Col>
                                <Col xs={12} className="mt-3"><p className="ego-text-description">{data[textBlock]["check-24"].body}</p></Col>
                            </Row>
                            :
                            <Row className="justify-content-center">
                                <Col md={9} lg={10}>
                                    <h2 className="ego-text-name">{data[textBlock]["check-24"].title}</h2>
                                    <p className="ego-text-description">{data[textBlock]["check-24"].body}</p>
                                </Col>
                                <Col md={3} lg={2} className="d-flex flex-wrap align-items-center"><Image src={Check24Image} className="object-fit-cover w-100" /></Col>
                            </Row>
                    }
                    <Row>
                        {
                            isMobile ?
                                <Col md={12} className="mb-2"><Image src={Devider3} fluid className="w-100" /></Col>
                                :
                                <Col md={12} className="mt-5 mb-4"><Image src={Devider3} fluid className="object-fit-cover w-100 h-100" /></Col>
                        }
                    </Row>
                    {
                        isMobile ?
                            <Row className="justify-content-center align-items-end">
                                <Col xs={8} className="align-items-end"><h2 className="ego-text-name mb-0">{data[textBlock]["customer-portal"].title}</h2></Col>
                                <Col xs={4}><Image src={PortalImage} className="object-fit-cover w-100" /></Col>
                                <Col xs={12} className="mt-3"><p className="ego-text-description">{data[textBlock]["customer-portal"].body}</p></Col>
                            </Row>
                            :
                            <Row className="justify-content-center">
                                <Col md={3} lg={2} className="d-flex flex-wrap align-items-center"><Image src={PortalImage} className="object-fit-cover w-100" /></Col>
                                <Col md={9} lg={10}>
                                    <h2 className="ego-text-name">{data[textBlock]["customer-portal"].title}</h2>
                                    <p className="ego-text-description">{data[textBlock]["customer-portal"].body}</p>
                                </Col>
                            </Row>
                    }
                    <Row>
                        {
                            isMobile ?
                                <Col md={12}><Image src={Devider2} fluid className="w-100" /></Col>
                                :
                                <Col md={12} className="mt-5 mb-4"><Image src={Devider2} fluid className="object-fit-cover w-100 h-100" /></Col>
                        }
                    </Row>
                </Container>
            </div>
        </section>
    )
}